.ClubRegistrImg {
  opacity: 0.1;
  height: 80%;
  border-radius: 25px;
}

.ClubRegistrContent {
  position: absolute;
  padding: 30px;
  color: black;
}

.ClubRegistrContent p {
  direction: rtl;
}

@media only screen and (max-width: 500px) {
  .clubRegister {
    padding-bottom: 56px;
  }

  .ClubRegistrImg {
    opacity: 0.3;
    height: 87%;
    object-fit: cover;
  }

  .ClubRegistrContent {
    padding: 15px;
  }

  [dir="ltr"] .ClubRegistrContent {
    padding: 0 30px 0 15px;
  }

  .clubRegister h1 {
    font-size: 1.5rem;
  }

  .clubRegister .ClubRegistrContent p {
    font-size: 0.8rem;
  }

  .clubRegister .ClubRegistrContent form {
    padding: 0 !important;
  }

  .clubRegister .ClubRegistrContent input {
    font-size: 0.8rem;
  }

  .clubRegister .ClubRegistrContent button {
    font-size: 0.7rem;
  }
}
