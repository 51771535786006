.paymentHestoryTemplate {
  padding: 60px 0;
}
.paymentHestoryCards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
@media only screen and (max-width: 991px) {
  .paymentHestoryCards {
    grid-template-columns: 1fr;
  }
}
